<template>
  <div class="setting">
    <div class="title-wrapper">
      <div class="title">{{ title }}</div>
      <div class="btns">
        <img :src="imgUrl"
             @click.stop="controlMap('lock')"
             alt />
        <img :src="icons.Back"
             @click.stop="controlMap('back', 1)"
             alt />
        <img :src="icons.Delete"
             @click.stop="controlMap('back', -1)"
             alt />
      </div>
    </div>
    <div class="infos"
         v-if="isLine">
      <div class="info-item">
        <div class="title">航点数</div>
        <div class="data">{{ config.airLinePoints | formatByZero }}</div>
      </div>
      <div class="info-item">
        <div class="title">航线长度</div>
        <div class="data">
          {{ config.ariLineLength | formatByZero | formatOnlyKm }}
        </div>
      </div>
      <div class="info-item">
        <div class="title">飞行时间</div>
        <div class="data">{{ formatTime(config.flyingExpectTime) }}</div>
      </div>
    </div>
    <div class="infos no-bottom"
         v-if="!isLine">
      <div class="info-item">
        <div class="title">区域面积</div>
        <div class="data">{{ config.airArea | formatByZero }}m<sup>2</sup></div>
      </div>
      <div class="info-item">
        <div class="title">航线长度</div>
        <div class="data">
          {{ config.ariLineLength | formatByZero | formatOnlyKm }}
        </div>
      </div>
    </div>
    <div class="infos"
         v-if="!isLine">
      <div class="info-item">
        <div class="title">预计飞行时间</div>
        <div class="data">{{ formatTime(config.flyingExpectTime) }}</div>
      </div>
      <div class="info-item">
        <div class="title">拍照张数</div>
        <div class="data">{{ config.photoGraphTimes | formatByZero }}张</div>
      </div>
    </div>
    <div class="line-settings">
      <div class="l-title">飞行设置</div>
      <div class="lines">
        <template v-if="isLine">
          <div class="line-item">
            <div class="title">
              <pre>飞行速度 (m/s)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.speed.max"
                        :min="rangeConfig.speed.min"
                        show-input
                        v-model="config.speed"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.speed | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.speed"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>飞行高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        show-input
                        v-model="config.height"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.height | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.height"
                     @on-change="makeValInRange($event, 'height')"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item select">
            <div class="title">
              <pre>任务完成动作</pre>
            </div>
            <div class="line-bottom">
              <Select placeholder
                      v-model="config.select"
                      size="small"
                      style="width: 70%">
                <Option v-for="item in list"
                        :value="item.value"
                        :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
          </div>
          <!-- 航线任务航点设置 -->
          <template v-if="config.activeMarker !== -1">
            <div class="line-item">
              <div class="title point">
                航点
                <span>{{ config.activeMarker }}</span>
              </div>
            </div>
            <div class="line-item">
              <div class="title">
                <pre>飞行高度 (m)</pre>
              </div>
              <div class="line-bottom">
                <div class="line">
                  <Slider :max="rangeConfig.height.max"
                          :min="rangeConfig.height.min"
                          show-input
                          v-model="
                      singlePointList[config.activeMarker - 1].pointHeight
                    "
                          show-tip="never"
                          :step="1"></Slider>
                </div>
                <!-- <div class="data">{{singlePointList[config.activeMarker - 1].pointHeight | formatByZero}}</div> -->
                <!-- <Input class="data"
                       :number="true"
                       v-model="singlePointList[config.activeMarker - 1].pointHeight"
                       placeholder="" /> -->
              </div>
            </div>
            <div class="line-item">
              <div class="title">
                <pre>云台俯仰角度 (°)</pre>
              </div>
              <div class="line-bottom">
                <div class="line">
                  <Slider :max="rangeConfig.angle.max"
                          :min="rangeConfig.angle.min"
                          show-input
                          v-model="
                      singlePointList[config.activeMarker - 1].pointAngle
                    "
                          show-tip="never"
                          :step="1"></Slider>
                </div>
                <!-- <div class="data">{{singlePointList[config.activeMarker - 1].pointAngle | formatByZero}}</div> -->
                <!-- <Input class="data"
                       :number="true"
                       v-model="singlePointList[config.activeMarker - 1].pointAngle"
                       placeholder="" /> -->
              </div>
            </div>
            <!-- 航点动作 -->
            <div v-for="(item,index) in singlePointList[config.activeMarker - 1].waypointActions"
                 :key="index"
                 class="route-actives">
              <Select v-model="item.value"
                      :transfer='true'
                      style="width:120px"
                      size="small">
                <Option v-for="active in actionType"
                        :value="active.value"
                        :key="active.value">{{ active.label }}</Option>
              </Select>
              <InputNumber v-if="item.value=='ROTATE_AIRCRAFT'"
                           controls-outside
                           :max="180"
                           :min="-180"
                           size="small"
                           :active-change='false'
                           style="width:100px"
                           v-model="item.actionParam"></InputNumber>
              <InputNumber v-else-if="item.value=='STAY'"
                           controls-outside
                           :max="3"
                           :min="1"
                           size="small"
                           :active-change='false'
                           style="width:100px"
                           v-model="item.actionParam"></InputNumber>

              <Icon type="md-trash"
                    size="20"
                    @click="handleRemove(index)" />
            </div>
            <Button type="dashed"
                    size="small"
                    style="width:120px"
                    @click="handleAdd()"
                    icon="md-add">添加动作</Button>
          </template>
        </template>
        <!-- 建图任务设置 -->
        <template v-else>
          <div class="line-item">
            <div class="title">
              <pre>飞行速度 (m/s)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.speed.max"
                        :min="rangeConfig.speed.min"
                        v-model="config.speed"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.speed}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.speed"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>飞行高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.height"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.height | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.height"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>目标高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.targetHeight"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.height | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.targetHeight"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>相对高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <!-- <div class="line">
                <Slider :disabled="true"
                        :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.relativeHeight"
                        show-tip="never"
                        :step="1"></Slider>
              </div> -->
              <div class="data">{{ config.relativeHeight | formatByZero }}</div>
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>航向重叠率 (%)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.percent.max"
                        :min="rangeConfig.percent.min"
                        v-model="config.courseOverlapRate"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.courseOverlapRate | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.courseOverlapRate"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>旁向重叠率 (%)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.percent.max"
                        :min="rangeConfig.percent.min"
                        v-model="config.sideOverlapRate"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.sideOverlapRate | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.sideOverlapRate"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>航线角度 (°)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="180"
                        :min="-180"
                        v-model="config.courseAngle"
                        show-tip="never"
                        show-input
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.courseAngle | formatByZero}}</div> -->
              <!-- <Input class="data"
                     :number="true"
                     v-model="config.courseAngle"
                     placeholder="" /> -->
            </div>
          </div>
          <div class="line-item select">
            <div class="title">
              <pre>任务完成动作</pre>
            </div>
            <div class="line-bottom">
              <Select placeholder
                      v-model="config.select"
                      size="small"
                      style="width: 70%">
                <Option v-for="item in list"
                        :value="item.value"
                        :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
          </div>
        </template>
        <div class="submit">
          <Button :loading="submitLoading"
                  type="primary"
                  @click.native="submits">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import slam from '@/assets/img/statistics/slam1.png';
import slamLock from '@/assets/img/statistics/slam-lock.png';
import Route from '@/assets/img/statistics/route.png';
import RouteLock from '@/assets/img/statistics/route-lock.png';
import Back from '@/assets/img/statistics/back.png';
import Delete from '@/assets/img/statistics/delete.png';
import filterMixin from '@/utils/filter.mixin.js';

export default {
  name: 'setting',
  mixins: [filterMixin],
  props: {
    submit: {
      type: Boolean,
      default: () => false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        slam,
        slamLock,
        Route,
        RouteLock,
        Back,
        Delete,
      },
      rangeConfig: {
        speed: {
          max: 15,
          min: 1,
        },
        height: {
          max: 500,
          min: 0,
        },
        percent: {
          max: 90,
          min: 10,
        },
        angle: {
          max: 0,
          min: -90,
        },
      },
      mapControl: {
        isMapOperateLocked: true,
        markLength: 0,
      },
      slider: 1,
      list: [
        {
          label: '自动返航',
          value: 1,
        },
        {
          label: '原地降落',
          value: 2,
        },
        {
          label: '停止在最后一个执行点',
          value: 3,
        },
      ],
      loading: false,
      lastSinglePointListLength: 0,
      singlePointList: [],
      submitLoading: false,
      actionType: [
        {
          value: 'START_TAKE_PHOTO',
          label: '拍照',
        },
        {
          value: 'START_RECORD',
          label: '开始录像',
        },
        {
          value: 'STOP_RECORD',
          label: '停止录像',
        },
        {
          value: 'STAY',
          label: '悬停',
        },
        {
          value: 'ROTATE_AIRCRAFT',
          label: '飞机偏航角',
        },
      ],
    };
  },
  watch: {
    config: {
      deep: true,
      handler: function (val) {
        if (val.airLinePoints !== this.lastSinglePointListLength) {
          // 地图添加了一个新的marker 这边同步更新下数据
          this.singlePointList = this.generatePointList();
          //   this.$emit('syncMapSinglePointList', this.singlePointList);
          this.lastSinglePointListLength = val.airLinePoints;
        }
      },
    },
    'config.height': _.debounce(function (val) {
      // 这边更新了配置 地图那边同步下更新下航线
      if (this.isLine) {
        this.changeAllHeight(val);
        // this.$emit('syncMapSinglePointList', this.singlePointList);
      } else {
        this.$emit('updateAreaAutoLine', this.config);
      }
      this.updateRelativeHeight();
    }, 500),
    'config.targetHeight': _.debounce(function (val) {
      // 更新相对高度就好了
      this.updateRelativeHeight();
    }, 500),
    'config.courseAngle': _.debounce(function (val) {
      // 这边更新了配置 地图那边同步下更新下航线
      this.$emit('updateAreaAutoLine', this.config);
    }, 500),
    'config.sideOverlapRate': _.debounce(function (val) {
      // 这边更新了配置 地图那边同步下更新下航线
      this.$emit('updateAreaAutoLine', this.config);
    }, 500),
    'config.courseOverlapRate': _.debounce(function (val) {
      // 这边更新了配置 地图那边同步下更新下航线
      this.$emit('updateAreaAutoLine', this.config);
    }, 500),
    singlePointList: {
      deep: true,
      handler: function (val) {
        // 这边更新了配置 地图那边同步下更新下航线
        this.$emit('syncMapSinglePointList', val);
      },
    },
  },
  computed: {
    // 建线任务
    isLine() {
      return this.config.taskType !== 'area';
    },
    // 标题
    title: function () {
      return this.isLine ? '航线设置' : '建图设置';
    },
    // 编辑任务的时候切换icon
    imgUrl() {
      if (this.isLine && this.mapControl.isMapOperateLocked) {
        return this.icons.RouteLock;
      } else if (this.isLine && !this.mapControl.isMapOperateLocked) {
        return this.icons.Route;
      } else if (!this.isLine && this.mapControl.isMapOperateLocked) {
        return this.icons.slamLock;
      } else if (!this.isLine && !this.mapControl.isMapOperateLocked) {
        return this.icons.slam;
      }
    },
    // 2级工程
    pmInfo() {
      return JSON.parse(sessionStorage.getItem('Project2info') || '{}');
    },
    // 任务信息
    taskInfo() {
      return JSON.parse(sessionStorage.getItem('ProjectTaskinfo') || '{}');
    },
    // 确保相对高度大于20
    isRelativeHeightSafe() {
      return this.config.relativeHeight >= 20;
    },
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'));
    this.userType = sessionStorage.getItem('userType');
  },
  methods: {
    // 更新相对高度
    updateRelativeHeight() {
      if (this.isLine) return;
      this.config.relativeHeight =
        this.config.height - this.config.targetHeight;
      if (this.config.relativeHeight < 20) {
        this.$notice.error({
          title: '相对高度必须大于20m!',
        });
      }
    },
    // input输入框在超出最大值的时候不触发更新事件了 导致不更新dom页面了 导致需要手动给它复位到范围以内 但是毕竟麻烦处理 目前还没解决 保留
    // makeValInRange(e, type) {
    //   let inputVal = parseInt(e.target.value);
    //   if (inputVal > this.rangeConfig[type].max) {
    //     e.target.setAttribute('value', this.rangeConfig[type].max);
    //     this.config[type] = this.rangeConfig[type].max;
    //     return;
    //   }
    //   if (inputVal < this.rangeConfig[type].min) {
    //     e.target.value = this.rangeConfig[type].min;
    //     this.config[type] = this.rangeConfig[type].min;
    //     return;
    //   }
    //   this.config[type] = inputVal;
    // },
    // 改变了整体高度 每个点同步
    changeAllHeight(val) {
      this.singlePointList.forEach((ele) => {
        ele.pointHeight = val;
      });
    },
    // 控制栏3个按钮的作用
    controlMap(type, backStep) {
      if (type === 'lock') {
        this.mapControl.isMapOperateLocked =
          !this.mapControl.isMapOperateLocked;
        this.mapControl.markLength = this.config.points.length;
      } else {
        if (this.mapControl.isMapOperateLocked || !this.config.points.length)
          return;
        if (backStep === -1) {
          this.mapControl.markLength = 0;
        } else {
          this.mapControl.markLength = this.config.points.length - backStep;
        }
      }
      this.$emit('mapControl', this.mapControl);
    },
    // 生成全新的单个航点数据
    generatePointList() {
      let ret = [];
      let pL = this.singlePointList.length - 1;
      for (let i = 0, L = this.config.airLinePoints; i < L; i++) {
        if (this.config.waypoint.length > 0) {
          ret.push({
            pointHeight:
              parseFloat(this.config.waypoint[i].altitude) ||
              this.config.height,
            pointAngle: parseFloat(this.config.waypoint[i].gimbalPitch) || 0,
          });
        } else {
          if (i <= pL) {
            ret.push({
              pointHeight: this.singlePointList[i].pointHeight,
              pointAngle: this.singlePointList[i].pointAngle,
              waypointActions: this.singlePointList[i].waypointActions,
            });
          } else {
            ret.push({
              pointHeight: this.config.height,
              pointAngle: 0,
              waypointActions: [{ value: 'START_TAKE_PHOTO', actionParam: 0 }],
            });
          }
        }
      }
      this.config.waypoint = [];
      return ret;
    },
    // 接口提交的时候需要映射数据
    getWayPoint() {
      let ret = [];
      for (let i = 0; i < this.config.airLinePoints; i++) {
        let actives = [];
        let distance = 0;
        this.singlePointList[i].waypointActions.forEach((element) => {
          if (element.value === 'STAY') {
            if (element.actionParam < 1) {
              actives.push({ actionType: 'STAY', actionParam: 1000 });
            } else {
              actives.push({
                actionType: 'STAY',
                actionParam: element.actionParam * 1000,
              });
            }
          } else {
            actives.push({
              actionType: element.value,
              actionParam: element.actionParam,
            });
          }
        });
        ret.push({
          order: i,
          coordinate_latitude: this.config.points[i][1],
          coordinate_longitude: this.config.points[i][0],
          altitude: this.singlePointList[i].pointHeight,
          gimbalPitch: this.singlePointList[i].pointAngle,
          speed: this.config.speed,
          waypointActions: actives,
        });
        if (i > 0) {
          let p1 = [this.config.points[i - 1][0], this.config.points[i - 1][1]],
            p2 = [this.config.points[i][0], this.config.points[i][1]];
          distance = AMap.GeometryUtil.distance(p1, p2);
          if (distance >= 2000) {
            return false;
          }
        }
      }
      return ret;
    },
    // 截取出位置信息就好了
    getPoints() {
      return JSON.stringify(
        this.config.points.map((ele) => {
          return { lng: ele[0].toString(), lat: ele[1].toString() };
        })
      );
    },
    // 提交
    submits() {
      if (this.submitLoading) return;
      if (!Boolean(this.getWayPoint())) {
        this.$Message.error('航点间距不能超过2000米');
        return false;
      }
      let params;
      if (this.isLine) {
        // 航线
        params = {
          client: 'web',
          ...(!this.config.isEdit ? {} : { route_id: this.config.id || null }),
          airtype: this.config.taskType === '3d-line' ? '3' : '2',
          airdata: {
            team_id:
              this.config.team_id == 0
                ? this.user_info.team_id
                : this.config.team_id, // 团队id
            dir_id: this.config.dir_id == -1 ? 0 : this.config.dir_id, // 文件夹id
            homelat: ((this.config.points || [])[0] || [])[1] || null, //起始纬度
            homelng: ((this.config.points || [])[0] || [])[0] || null, //起始经度
            homeAltitude: 0, // 起始高度
            filetitle: this.config.taskName || null,
            routedistance: this.config.ariLineLength || 0, //航线长度
            flighttime: this.config.flyingExpectTime || 0, //飞计预计时间
            airrouteset: {
              waypointMissionFinishedAction:
                this.config.select.toString() || null, //任务完成动作
              setMissionHeight: (this.config.height || 0).toString(), //飞行高度
              setMissionSpeed: (this.config.speed || 0).toString(), //飞行速度
            },
            waypoint: this.getWayPoint(),
          },
        };
        // if (params.airdata.waypoint.length < 1) {
        //   this.$Message.error('航点间距不能超过2000米');
        //   return false;
        // }
      } else {
        // 建图
        if (!this.isRelativeHeightSafe) {
          this.$notice.error({
            title: '相对高度必须大于20m!',
          });
          return;
        }
        params = {
          client: 'web',
          airdata: {
            ...(!this.config.isEdit ? {} : { bf_id: this.config.id || null }),
            local_data: this.getPoints() || null, //数据坐标集
            filetitle: this.config.taskName || null,
            team_id:
              this.config.team_id == 0
                ? this.user_info.team_id
                : this.config.team_id, // 团队id
            dir_id: this.config.dir_id == -1 ? 0 : this.config.dir_id, // 文件夹id

            // firstPmID: this.pmInfo.pm_first_id || null, //一级工程id
            // secondPmID: this.pmInfo.pm_id || null, //二级工程id
            airrouteset: {
              figure_area: this.config.airArea || 0, //建图面积
              air_route_len: this.config.ariLineLength || 0, //长度
              flytime: Math.round(this.config.flyingExpectTime) || 0, //预计飞行时长
              imgcount: this.config.photoGraphTimes || 0, //拍照数量
              fly_speed: this.config.speed || 0, //飞行速度
              fly_height: this.config.height || 0, //飞行高度
              target_height: this.config.targetHeight || 0, //目标高度
              relative_height: this.config.relativeHeight || 0, //相对高度
              course_overlap: this.config.courseOverlapRate || 0, //航向重叠率
              next_overlap: this.config.sideOverlapRate || 0, //旁向重叠率
              Course_angle: this.config.courseAngle || 0, //航线角度
              endtype: this.config.select || null, //任务完成动作
            },
          },
        };
        console.log('建图', params.airdata);
      }
      this.$emit('submit', params);
    },
    formatTime(val) {
      return (
        (this.config.speed
          ? (this.config.ariLineLength / this.config.speed / 60).toFixed(2)
          : '0.00') + 'min'
      );
    },
    // 添加航点动作
    handleAdd() {
      let n = this.config.activeMarker - 1;
      this.singlePointList[n].waypointActions.push({
        value: 'START_TAKE_PHOTO',
        actionParam: 0,
      });
      console.log(n, this.singlePointList);
    },
    // 删除航点动作
    handleRemove(index) {
      console.log(index);
      let n = this.config.activeMarker - 1;
      this.singlePointList[n].waypointActions.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  font-size: 12px;
  color: #999;
  .title-wrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    .title {
      color: #333;
      font-size: 22px;
      font-weight: bold;
      flex: 1;
    }
    .btns {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .infos {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    // margin-bottom: 20px;
    .info-item {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      border-right: 1px solid #e1e1e1;
      white-space: nowrap;
      &:last-child {
        border-right: 0;
      }
      .title {
        font-weight: bold;
        font-size: 14px;
      }
      .data {
        font-size: 12px;
        color: #409eff;
      }
    }
  }
  .no-bottom {
    border-bottom: 0;
  }
  .line-settings {
    padding: 20px;
    .l-title {
      font-size: 17px;
      color: #666;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .lines {
      .line-item {
        padding-bottom: 30px;
        .title {
          margin-bottom: 4px;
        }
        .point {
          margin: 20px 0;
          font-size: 17px;
          font-weight: bold;
          color: #666;
          span {
            margin-left: 20px;
            color: #409eff;
            font-size: 17px;
            font-weight: bold;
          }
        }
      }
      .select {
        .title {
          color: #409eff;
          font-size: 14px;
        }
        .line-bottom {
          /deep/ .ivu-select-selection {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          /deep/ .ivu-select-dropdown {
            padding: 10px 20px;
          }
        }
      }
      .line-bottom {
        display: flex;
        align-items: center;
        .line {
          flex: 1;
          //   padding-right: 60px;
        }
        .data {
          text-align: center;
          flex: 0 0 60px;
          border-bottom: 1px solid #e1e1e1;
          color: #409eff;
          font-size: 14px;
          /deep/.ivu-input {
            color: #409eff;
            border: none;
            outline: none;
            text-align: center;
          }
        }
        /deep/.ivu-input-number {
          border: none;
          outline: none;
          text-align: center;
          border-bottom: 1px solid #e1e1e1;
          .ivu-input-number-input {
            color: #409eff;
          }
        }
      }
      .route-actives {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
    .submit {
      margin-top: 30px;
      text-align: center;
      button {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}
</style>
