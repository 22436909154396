<template>
  <div class="create-app">
    <Map :config="bigMapConfig"
         @calculateLine="updateSettings"
         @calculateArea="updateSettings"
         @updateActiveMarker="updateActiveMarker"
         @getAutoLinePointsNum="getAutoLinePointsNum"
         @toggleMapType="toggleMapType" />
    <folder-modal ref="folder"
                  :config="folderConfig"
                  @toggleFolder="toggleFolder">
      <setting @submit="submit"
               @mapControl="mapControl"
               @syncMapSinglePointList="syncMapSinglePointList"
               @updateAreaAutoLine="updateAreaAutoLine"
               :config="settingConfig"
               :submitLoading="submitLoading" />
    </folder-modal>
  </div>
</template>

<script>
const TASK_TYPE = {
  line_2_d: '2d-line',
  line_3_d: '3d-line',
  line_area: 'area',
};
import Api from '@/utils/api.js';
import Map from '@/components/statistics/commonComponents/map.vue';
import folderModal from '@/components/statistics/commonComponents/folderModal.vue';
import Setting from '@/components/airLine/commonComponents/air-line-setting.vue';
export default {
  name: 'create-app',
  components: {
    Map,
    folderModal,
    Setting,
  },
  created() {
    this.setPageState();
    this.mapTaskInfo();
  },
  data() {
    return {
      TASK_TYPE: TASK_TYPE,
      submitLoading: false,
      bigMapConfig: {
        show3DSwitchBtn: false,
        toolsMovingDistance: 340,
        tools: ['search', 'measure', 'hierarchy', 'zoomIn', 'zoomOut'],
        onEventHandler: false,
        markLength: 0,
        taskType: TASK_TYPE.line_2_d,
        reDraw: false,
        points: [],
        singlePointList: [],
        UpdateBysinglePointList: false,
        UpdateByNeedReRenderAutoLine: false,
        settingModalData: null,
      },
      showModal: false,
      folderConfig: {
        width: 340,
        backgroundColor: '#FFF',
        direction: 'left',
      },
      settingConfig: {
        taskType: TASK_TYPE.line_2_d, // 2d-line 3d-line area
        isEdit: false, // 创建 / 编辑
        task3DModel: '',
        taskName: '',
        id: '',
        activeMarker: -1,
        airLinePoints: 0,
        ariLineLength: 0,
        flyingExpectTime: 0,
        speed: 5,
        height: 50,
        targetHeight: 30,
        relativeHeight: 20,
        select: 1,
        pointHeight: 50,
        pointAngle: 0,
        airArea: 0,
        photoGraphTimes: 0,
        courseOverlapRate: 80,
        sideOverlapRate: 70,
        courseAngle: 0,
        points: [],
        waypoint: [],
        defaultFov: 84,
        LinePointsNum: 0,
      },
    };
  },
  computed: {
    // 建线任务
    isLine() {
      return this.settingConfig.taskType !== this.TASK_TYPE.line_area;
    },
  },
  methods: {
    updateAreaAutoLine(data) {
      // setting那边改了自动规划航线的参数 派发事件 这边通知map重新画图
      this.bigMapConfig.UpdateByNeedReRenderAutoLine = true;
    },
    syncMapSinglePointList(list) {
      // setting那边改了每个航线的点的参数 派发事件 这边通知map重新更新marker
      //   this.bigMapConfig.singlePointList = list;
      //   this.bigMapConfig.UpdateBysinglePointList = true;
    },
    setPageState() {
      if (this.$route.query.taskName) {
        // 创建情况
        this.settingConfig.isEdit = false;
        this.bigMapConfig.taskType = this.settingConfig.taskType =
          this.$route.query.taskType === 'line'
            ? this.TASK_TYPE.line_2_d
            : this.TASK_TYPE.line_area;
        this.settingConfig.taskName = this.$route.query.taskName;
      } else {
        // 编辑情况
        this.settingConfig.isEdit = true;
        this.taskInfo = JSON.parse(
          sessionStorage.getItem('ProjectTaskinfo') || '{}'
        );

        this.bigMapConfig.taskType = this.settingConfig.taskType =
          this.taskInfo.tasktype === 'figure'
            ? this.TASK_TYPE.line_area
            : this.taskInfo.airroutetype === 2
            ? this.TASK_TYPE.line_2_d
            : this.TASK_TYPE.line_3_d;
        this.settingConfig.taskName = this.taskInfo.filetitle;
        this.settingConfig.id = this.taskInfo.id;
      }
      this.settingConfig.team_id = parseInt(this.$route.query.team_id);
      this.settingConfig.dir_id = parseInt(this.$route.query.dir_id);
      this.bigMapConfig.settingModalData = this.settingConfig;
    },
    mapTaskInfo(params) {
      // 页面上面的数据字段最开始不和后台统一  做了映射
      if (this.settingConfig.isEdit) {
        let params = this.taskInfo;
        if (this.settingConfig.taskType !== this.TASK_TYPE.line_area) {
          this.bigMapConfig.points = params.waypoint.map((ele) => {
            return [parseFloat(ele.lng) || 0, parseFloat(ele.lat) || 0];
          });
          this.settingConfig.ariLineLength = params.routedistance;
          this.settingConfig.flyingExpectTime = params.flighttime;
          this.settingConfig.speed =
            parseFloat(params.airrouteset[0].setMissionSpeed) || 0;
          this.settingConfig.height =
            parseFloat(params.airrouteset[0].setMissionHeight) || 0;
          this.settingConfig.select =
            parseFloat(params.airrouteset[0].waypointMissionFinishedAction) ||
            -1;
          this.settingConfig.waypoint = params.waypoint;
        } else {
          this.bigMapConfig.points = params.local_data.map((ele) => {
            return [parseFloat(ele.lng) || 0, parseFloat(ele.lat) || 0];
          });
          this.settingConfig.ariLineLength = params.air_route_len;
          this.settingConfig.flyingExpectTime = params.flytime;
          this.settingConfig.speed = params.fly_speed;
          this.settingConfig.height = params.fly_height;
          // 目标高度 相对高度
          this.settingConfig.targetHeight = params.target_height;
          this.settingConfig.relativeHeight = params.relative_height;

          this.settingConfig.select = params.endtype;
          this.settingConfig.airArea = params.figure_area;
          this.settingConfig.photoGraphTimes = params.imgcount;
          this.settingConfig.courseOverlapRate = params.course_overlap;
          this.settingConfig.sideOverlapRate = params.next_overlap;
          this.settingConfig.courseAngle = params.Course_angle;
        }
        this.$nextTick(() => {
          // 编辑状态下需要地图重绘所有的数据 目前功能没有UI展示 后续如果有点击任务进入编辑状态 会用到这里的代码
          this.bigMapConfig.reDraw = true;
          this.bigMapConfig.show3DSwitchBtn = false;
          this.bigMapConfig.mapType =
            this.settingConfig.taskType === this.TASK_TYPE.line_3_d
              ? '3d'
              : '2d';
          this.reDraw = true;
        });
      } else {
        this.bigMapConfig.show3DSwitchBtn =
          this.settingConfig.taskType !== this.TASK_TYPE.line_area &&
          !!this.$route.query.task3DModel;
      }
      return this.settingConfig;
    },
    mapControl(val) {
      this.bigMapConfig.onEventHandler = !val.isMapOperateLocked;
      this.bigMapConfig.markLength = val.markLength;
    },
    toggleFolder() {
      this.bigMapConfig.toolsMovingDistance = this.bigMapConfig
        .toolsMovingDistance
        ? 0
        : 340;
    },
    updateSettings(params) {
      // 地图那边marker更新了 通知setting同步展示数据
      if (this.isLine) {
        this.settingConfig.airLinePoints = params.lineCount;
        this.settingConfig.ariLineLength = params.lineLength;
      } else {
        this.settingConfig.airArea = params.airArea;
        this.settingConfig.ariLineLength = params.autoLineLength;
      }
      this.settingConfig.flyingExpectTime =
        this.settingConfig.ariLineLength / this.settingConfig.speed;
      this.settingConfig.points = params.points;
      this.bigMapConfig.markLength = params.points.length;
    },
    // 更新当前选中的marker
    updateActiveMarker(params) {
      if (this.isLine) {
        this.settingConfig.activeMarker = params.activeMarker;
      }
    },
    // 切换2d3d航线
    toggleMapType(type) {
      this.settingConfig.taskType =
        type === '2d' ? this.TASK_TYPE.line_2_d : this.TASK_TYPE.line_3_d;
    },
    // 建图任务航点数
    getAutoLinePointsNum(n) {
      this.LinePointsNum = n;
      console.log('建图任务航点数', this.LinePointsNum);
    },
    // 提交
    submit(params) {
      console.log(params);
      if (this.isLine && params.airdata.waypoint.length <= 1) {
        this.$Message.error('当前未设置航线');
        return;
      }
      if (!this.isLine) {
        if (this.LinePointsNum > 99) {
          this.$Message.error('建图任务航点数不能超过99');
          return;
        } else if (this.LinePointsNum <= 0) {
          this.$Message.error('建图任务航点数不能少于0');
          return;
        }
      }
      this.submitLoading = true;
      let url;
      if (this.isLine && !this.settingConfig.isEdit) {
        console.log('dir hx  add');
        url = Api.submitCreateLine();
      } else if (this.isLine && this.settingConfig.isEdit) {
        url = Api.submitEditLine();
      } else if (!this.isLine && !this.settingConfig.isEdit) {
        url = Api.submitCreateArea();
      } else if (!this.isLine && this.settingConfig.isEdit) {
        url = Api.submitEditArea();
      }
      this.$post(url, params)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$Notice.success({
              title: this.settingConfig.isEdit
                ? '修改任务成功'
                : '添加任务成功',
            });
            setTimeout(() => {
              // 编辑状态返回一层即可
              if (this.reDraw) {
                this.$router.go(-1);
              } else {
                this.$router.go(-2);
              }
            }, 500);
          } else {
            this.$Notice.warning({
              title: this.settingConfig.isEdit
                ? '修改任务失败'
                : '添加任务失败',
              desc: res.msg_customer,
            });
            setTimeout(() => {
              // 编辑状态返回一层即可
              if (this.reDraw) {
                this.$router.go(-1);
              } else {
                this.$router.go(-2);
              }
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-app {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>

